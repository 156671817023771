* {
  text-align: center;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  padding: 25px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media (orientation: landscape) {
  main {
    padding: 50px;
  }
}

a {
  text-decoration: none;
  color: black;
}

button {
  font-size: 14pt;
  /* font-weight: 800; */
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 1000px;
  padding: 5px 10px;
}