.titleCards {
  min-height: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-evenly;
}

.titleCard {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 50px);
  height: calc(((100vw - 50px)*0.6666667) + 100px);
  border-radius: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
}

@media (orientation: landscape) {
  .titleCards {
    padding: 50px 50px 0px 50px;
  }

  .titleCard {
    width: calc(50vw - 150px);
    height: calc(((50vw - 150px)*0.6666667) + 100px)
  }
}

.titleCard img,
.titleCard video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
  transition: all 200ms ease-in-out;
}

.titleCard img:hover,
.titleCard video:hover {
  transform: scale(1.025);
}

@media (orientation: landscape) {

  .titleCard img:hover,
  .titleCard video:hover {
    transform: scale(1.05);
  }
}

.projectInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: calc(100% - 50px);
  height: 100px;
  padding: 0px 25px;
  position: absolute;
  bottom: 0;
}

.projectInfo h1 {
  font-size: 24px;
}

@media (orientation: landscape) {
  .projectInfo h1 {
    font-size: 32px;
  }
}