.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
  padding: 25px;
}

.navbar a {
  color: white;
  font-weight: 600;
  margin: 0px 10px;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar img {
  height: 50px;
  transition: all 200ms ease-in-out;
}

.navbar img:hover {
  transform: scale(1.1);
}

.activeLink {
  text-decoration: underline;
}

@media (orientation: portrait) {
  .navbar {
    padding: 20px;
  }

  .navbar img {
    height: 25px;
    transition: all 200ms ease-in-out;
  }
}