.playMain {
  padding: 25px;
}

.p5Sketch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p5Canvas {
  margin-bottom: 10px;
}

.btnCont,
.sliderCont,
.sliderCont div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.radioCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.sliderCont h3 {
  margin-right: 10px;
}

.p5Sketch button {
  font-weight: 600;
  font-size: 18px;
  padding: 5px 15px;
  margin: 10px 0px;
  color: #000000;
}

.p5Sketch h1 {
  margin-bottom: 25px;
}

.p5Sketch h3 {
  font-size: 18px;
}

.p5Sketch input {
  accent-color: #000000;
}

.p5Sketch label {
  padding: 0px 10px;
}

.p5Sketch label input {
  margin-right: 5px;
}

.react-p5-wrapper div {
  padding: 10px 0px;
}