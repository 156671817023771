.email {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 300px); */
}

.email a {
  display: block;
  text-decoration: underline;
  margin-top: 10px;
}